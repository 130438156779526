import colors from "@vertikal/e-prospera.styles.colors/dist/colors";
import { navigate } from "gatsby";
import { I18nextContext, useI18next } from "gatsby-plugin-react-i18next";
import Cookies from "js-cookie";
import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { createGlobalStyle } from "styled-components";
import faviconApple from "../images/apple-touch-icon.png";
import favicon16x16 from "../images/favicon-16x16.png";
import favicon32x32 from "../images/favicon-32x32.png";

const GlobalStyle = createGlobalStyle`
  body {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: "Avenir Next";
      color: ${colors.gray.c900};
  }
  .no-decoration {
    text-decoration: none;
  }
  .inline-link {
    :visited, :active, :focus {
      color: inherit;
    }
    :hover {
      text-decoration: none;
    }
    color: inherit;
  }
`;

const GlobalSettings = () => {
  const context = React.useContext(I18nextContext);
  const cookieLanguage = Cookies.get("user-preferred-language");
  const { changeLanguage } = useI18next();
  const changeLang = useCallback(
    (lang) => {
      return changeLanguage(lang);
    },
    [changeLanguage]
  );
  useEffect(() => {
    if (cookieLanguage && context.language !== cookieLanguage) {
      changeLang(cookieLanguage);
      if (
        (window.location.pathname === "/es" ||
          window.location.pathname === "/es/") &&
        cookieLanguage === "en"
      ) {
        navigate("/");
      }
    }
  }, [context.language, changeLang, cookieLanguage]);

  return (
    <>
      <Helmet>
        <html lang={cookieLanguage} />
        <link rel="apple-touch-icon" sizes="180x180" href={faviconApple} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32x32} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16x16} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
      </Helmet>
      <GlobalStyle />
    </>
  );
};
export default GlobalSettings;
